import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const S = {
  Мишень: styled.div`
    max-width: 90vw;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  `,

  Картинка: styled.img`
    width: 100%;
    display: block;
    max-width: 500px;
    animation: 10s ${fade} 10s infinite alternate;
  `,

  Link: styled(Link)`
    text-decoration: none;
    position: relative;
    display: block;
    transform: translateX(15px);

    &:active {
      transform: translateX(15px) translateY(3px);
    }
  `,
};

export const ScreenEntry = () => {
  // Create "typing" text effect
  const text = "🏹";
  const [textToShow, setTextToShow] = useState("");
  const [index, setIndex] = useState(0);

  const getRandomEmoji = () => {
    const emojis = ["🤪", "🤩", "😎", "🤓", "😜", "🤠", "🤡"];
    return emojis[Math.floor(Math.random() * emojis.length)];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Replace - with random non-letter character, but every replacement should be different
      const ttt = text
        .slice(0, index)
        .split("")
        .map((c) => (c === "-" ? getRandomEmoji() : c))
        .join("");

      setTextToShow(ttt);

      setIndex(index + 1);

      if (index === text.length) {
        setTimeout(() => {
          setIndex(0);
        }, 2000);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [textToShow, index]);

  // const [hueRotate, setHueRotate] = useState(0);
  // const [invert, setInvert] = useState(0);

  // useEffect(() => {
  //   // set random hue rotate once
  //   setHueRotate(Math.floor(Math.random() * 360));

  //   // set random invert: either 0 or 1
  //   setInvert(Math.round(Math.random()));
  // }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      <S.Link
        to="/works"
        style={
          {
            // Random hue rotate
            // filter: `hue-rotate(${hueRotate}deg) saturate(2) invert(${invert})`,
          }
        }
      >
        {/* <S.Картинка src={require("./images/letoile-2.jpg")} alt="Bendy" /> */}
        <S.Мишень>🎯</S.Мишень>
      </S.Link>
    </div>
  );
};
