import { ScreenEntry } from "./screens/entry";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ScreenWorks } from "./screens/works";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ScreenEntry />,
  },
  {
    path: "/works",
    element: <ScreenWorks />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
